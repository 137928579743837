<template>
  <section :class="$style.seo">
    <adw-tabs
      :tabs="seoFaqTabs"
      :active-tab="activeSeoFaqTab"
      color="blue"
      label="label"
      :class="$style.tabs"
      @change="handleChangeTab"
    ></adw-tabs>
    <div :class="$style.wrapper">
      <div :class="$style.search">
        <Autocomplete
          :search="search"
          placeholder="Поиск"
          :valueNames="['question']"
          @querySearch="querySearch"
          @selectItem="handleSelectSeo"
          @handleFindItems="handleFindSeos"
          @input="search = $event"
        />
        <el-button :class="$style.button" type="primary" @click="getList">
          Показать
        </el-button>
      </div>
      <el-button type="primary" @click="$router.push('seo-faqs/create')">
        Создать SEO-FAQ
      </el-button>
    </div>
    <el-table :data="seoFAQs" stripe>
      <el-table-column prop="question" label="Вопрос"> </el-table-column>
      <el-table-column
        prop="answer"
        label="Ответ"
        :show-overflow-tooltip="true"
      >
        <template slot-scope="scope">
          <div :class="$style.answer" v-html="scope.row.answer"></div>
        </template>
      </el-table-column>

      <el-table-column label="Где используется">
        <template slot-scope="scope">
          <adw-router :link="getLink(scope.row)" target="_blank">
            {{ getLink(scope.row) }}</adw-router
          >
        </template>
      </el-table-column>
      <el-table-column width="80">
        <template slot-scope="scope">
          <ActionButtons
            name="trash"
            :edit-link="'seo-faqs/' + String(scope.row.id)"
            @delete="remove(scope.row)"
          />
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :class="$style.pagination"
      layout="prev, pager, next"
      :page-size="limit"
      :total="total"
      :current-page.sync="page"
      @current-change="getList"
      background
    >
    </el-pagination>
  </section>
</template>

<script>
import delivery from '@/delivery'

import {
  SEO_TABS_TYPES,
  EXTERNAL_PAGES_TYPES,
  EXTERNAL_PAGES_ROUTES,
} from '@/constants/seo'
import {
  ADDWINE_ADMIN_ROUTES,
  getRoute,
  ADDWINE_ROUTES,
} from '@/constants/routing'
import { PROJECTS } from '@/constants/projects'

import ActionButtons from '@/components/atoms/ActionsButtons.vue'
import Autocomplete from '@/components/atoms/Autocomplete.vue'
export default {
  SEO_TABS_TYPES,
  ADDWINE_ADMIN_ROUTES,
  EXTERNAL_PAGES_TYPES,
  ADDWINE_ROUTES,
  PROJECTS,
  EXTERNAL_PAGES_ROUTES,
  components: {
    ActionButtons,
    Autocomplete,
  },
  data() {
    return {
      seoFAQs: [],
      limit: 20,
      page: 1,
      total: 10,
      search: '',
      activeSeoFaqTab: SEO_TABS_TYPES[0].value,
      seoFaqTabs: SEO_TABS_TYPES,
    }
  },
  created() {
    this.getList()
  },
  methods: {
    async getList() {
      const loading = this.$loading({
        lock: true,
      })

      const query = {
        limit: this.limit,
        page: this.page,
      }

      if (this.search) {
        query.search = this.search
      }

      if (this.activeSeoFaqTab === SEO_TABS_TYPES[1].value) {
        query.isLinked = true
      }

      if (this.activeSeoFaqTab === SEO_TABS_TYPES[2].value) {
        query.isLinked = false
      }

      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList(query)

      loading.close()

      if (error) {
        return
      }

      this.seoFAQs = value.data
      this.total = value.meta.count
    },

    async handleFindSeos() {
      await this.getList()
    },
    handleSelectSeo(selectedSeo) {
      this.$router.push(
        getRoute({
          route: ADDWINE_ADMIN_ROUTES.CONTENT.SEO_FAQS.VIEW,
          params: { id: selectedSeo.id },
        }),
      )
    },
    async querySearch({ queryString, setSearchItems }) {
      const { value, error } =
        await delivery.ContentServiceCore.SeoFaqsActions.getList({
          limit: this.limit,
          page: this.page,
          search: queryString,
        })

      if (error) return
      setSearchItems(value.data)
    },
    async remove(data) {
      const isConfirm = confirm(`Вы точно хотите удалить ${data.question}?`)
      if (isConfirm) {
        const res = await delivery.ContentServiceCore.SeoFaqsActions.delete(
          data.id,
        )
        if (!res.error) {
          this.getList()
        }
      }
    },
    getLink(seoFaq) {
      const site = PROJECTS.ADDWINE
      let routeOptions = null

      if (seoFaq?.contentPages?.length) {
        routeOptions = {
          route: seoFaq?.contentPages[0]?.slug,
          site,
        }
      }

      if (seoFaq?.articleCategories?.length) {
        routeOptions = {
          site,
          route: ADDWINE_ROUTES.ARTICLES.CATEGORY,
          params: {
            id: seoFaq?.articleCategories[0]?.slug,
          },
        }
      }

      if (seoFaq?.externalPages?.length) {
        const hasCorrectType = Object.keys(EXTERNAL_PAGES_TYPES).some(
          (type) => type === seoFaq?.externalPages[0]?.type,
        )

        if (!hasCorrectType) {
          return 'В построении ссылки возникли проблемы'
        }

        const typeRoute = EXTERNAL_PAGES_ROUTES[seoFaq?.externalPages[0]?.type]

        routeOptions = {
          site,
          route: typeRoute,
          params: {
            id: seoFaq?.externalPages[0]?.slug,
          },
        }
      }

      if (routeOptions) {
        return this.getRoute(routeOptions)
      }

      return ''
    },
    async handleChangeTab(event) {
      const previusActiveSeoFaqTab = this.activeSeoFaqTab

      this.activeSeoFaqTab = event

      if (previusActiveSeoFaqTab !== this.activeSeoFaqTab) {
        await this.getList()
      }
    },
    getRoute(routeOptions) {
      return getRoute(routeOptions)
    },
  },
}
</script>

<style lang="scss" module>
.seo {
  padding: 1rem;

  .tabs {
    margin-bottom: 2rem;
    & > li {
      list-style: none;

      & > span {
        @include H200;
      }
    }
  }

  .wrapper {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1rem;
    align-items: center;
    .button {
      margin-bottom: 1rem;
      margin-left: 0;
      margin-bottom: 0;
      border-radius: 0;
    }
    .search {
      display: flex;
      align-items: center;
      margin-right: auto;
      input {
        border-radius: 0;
      }
    }
  }

  .answer {
    display: flex;
    flex-direction: column;
    max-height: 1.4375rem;
  }
  .pagination {
    @include pagination;
  }
}
</style>
